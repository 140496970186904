'use strict';

/**
 * checkoutAddressSuggestEvent event
 */
function checkoutAddressSuggestEvent() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push({ event_name: 'site_interaction', site_interaction: 'loqate:select loqate address:checkout shipping' });
    $('body').trigger('datalayerEvent:view', dataLayerAnalytics);
}
/**
 * checkoutEditLineItemEvent event
 * @param {Object} data - data
 */
function checkoutEditLineItemEvent(data) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var product = data.product;
    var checkoutEditProductLineItemData = {};
    checkoutEditProductLineItemData.event_name = 'product_view';
    if (product.masterProductData) {
        checkoutEditProductLineItemData.product_style_id = product.masterProductData.ID;
        checkoutEditProductLineItemData.product_name = product.masterProductData.name;
    }

    if (product.allBadges !== null) {
        checkoutEditProductLineItemData.product_badge_name = product.allBadges;
    }

    if (product.variationAttributes) {
        var colorcodeVariationAttr = product.variationAttributes.find(variationAttribute => variationAttribute.attributeId === 'colorCode');
        if (colorcodeVariationAttr) {
            checkoutEditProductLineItemData.product_upc_color_name = colorcodeVariationAttr.displayValue;
            checkoutEditProductLineItemData.product_upc_color_code = colorcodeVariationAttr.colorcode;
        }
    }
    checkoutEditProductLineItemData.product_upc_id = product.id;
    if (product.size) {
        checkoutEditProductLineItemData.product_upc_size = product.size;
    }
    var prices = product.analyticaPriceData;
    checkoutEditProductLineItemData.product_price_msrp = prices.fullPrice ? prices.fullPrice.toString() : '';
    checkoutEditProductLineItemData.product_price_actual = prices.outOfDoorPrice ? prices.outOfDoorPrice.toString() : '';
    checkoutEditProductLineItemData.product_price_shortmark = prices.markdownPrice ? prices.markdownPrice.toString() : '';
    dataLayerAnalytics.datalayerEvent.push(checkoutEditProductLineItemData);
    $('body').trigger('datalayerEvent:view', dataLayerAnalytics);
}

/**
 * checkoutRemoveLineItemEvent event
 * @param {Object} data - data
 */
function checkoutRemoveLineItemEvent(data, errorCodes) {
    var itemRemoved = data.deletedPliAnalyticsData;
    if (errorCodes) {
        itemRemoved.event_name+= ',error_serverside';
        itemRemoved.error_serverside = errorCodes;
    }
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push(itemRemoved);
    $('body').trigger('datalayerEvent:view', dataLayerAnalytics);
}

/**
 * checkoutAddnewAddressEvent event
 * @param {Object} errorCodes - errorCodes
 */
function checkoutAddnewAddressEvent(optMail, errorCodes) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var addNewAddressObj = {};
    addNewAddressObj.event_name = 'checkout_shipping_method';
    if (optMail) {
        addNewAddressObj.event_name = 'checkout_shipping_method,email_signup_success';
        addNewAddressObj.email_signup_location = 'checkout shipping';
    }
    if (errorCodes && errorCodes.length > 0) {
        addNewAddressObj.event_name += ',error_serverside';
        addNewAddressObj.error_serverside = errorCodes;
    }
    dataLayerAnalytics.datalayerEvent.push(addNewAddressObj);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
module.exports = {
    checkoutAddressSuggestEvent: checkoutAddressSuggestEvent,
    checkoutEditLineItemEvent: checkoutEditLineItemEvent,
    checkoutRemoveLineItemEvent: checkoutRemoveLineItemEvent,
    checkoutAddnewAddressEvent: checkoutAddnewAddressEvent
};

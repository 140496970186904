'use strict';

/**
 * @param {Object} orderId - current order Id
 */
function editOrderOnLoad(orderId) {
    var dataLayerAnalytics = {
        datalayerEvent: [{ event_name: 'order_edit_start', order_id: orderId }]
    };
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
/**
 * @param {Object} orderId - current order Id
 */
function CancelOrderOnLoad(orderId) {
    var dataLayerAnalytics = {
        datalayerEvent: [{ event_name: 'order_cancel_start', order_id: orderId }]
    };
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
/**
 * @param {Object} orderId - current order Id
 */
function CancelOrderSuccess(orderId) {
    var dataLayerAnalytics = {
        datalayerEvent: [{ event_name: 'order_cancel_success', order_id: orderId }]
    };
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
/**
 * @param {Object} orderId - current order Id
 */
function EditOrderSuccess(orderId) {
    var dataLayerAnalytics = {
        datalayerEvent: [{ event_name: 'order_edit_success', order_id: orderId }]
    };
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
/**
 * even on load of account registration
 */
function accountRegisterOnload() {
    var dataLayerAnalytics = {
        datalayerEvent: [{ event_name: 'account_registration_formdisplay' }]
    };
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
/**
 * even on load of account registration success
 */
function accountRegisterSuccess() {
    var dataLayerAnalytics = {
        datalayerEvent: [{ event_name: 'account_creation_success', account_creation_location: 'order confirmation' }]
    };
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
module.exports = {
    editOrderOnLoad: editOrderOnLoad,
    CancelOrderOnLoad: CancelOrderOnLoad,
    CancelOrderSuccess: CancelOrderSuccess,
    EditOrderSuccess: EditOrderSuccess,
    accountRegisterOnload: accountRegisterOnload,
    accountRegisterSuccess: accountRegisterSuccess
};

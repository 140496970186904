'use strict';

/**
 * tealium event on click of edit product on cart page
 * @param {Object} data - response data
 */
function editProduct(data) {
    if (data) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var editProductData = {};
        var editModelOnloadDatalayer = data.datalayerEvent[0];
        editProductData = {
            event_name: 'grid_click_product,product_quick_view',
            product_style_id: [data.product.masterProductData.ID],
            product_name: [data.product.productName],
            product_price_msrp: editModelOnloadDatalayer.product_price_msrp,
            product_price_shortmark: editModelOnloadDatalayer.product_price_shortmark,
            product_price_actual: editModelOnloadDatalayer.product_price_actual
        };
        if (editModelOnloadDatalayer.product_badge_name) {
            editProductData.product_badge_name = editModelOnloadDatalayer.product_badge_name;
        }
        if (editModelOnloadDatalayer.product_upc_color_name && editModelOnloadDatalayer.product_upc_color_code) {
            editProductData.product_upc_color_code = editModelOnloadDatalayer.product_upc_color_code;
            editProductData.product_upc_color_name = editModelOnloadDatalayer.product_upc_color_name;
        }
        if (editModelOnloadDatalayer.product_upc_size) {
            editProductData.product_upc_size = editModelOnloadDatalayer.product_upc_size;
        }
        if (editModelOnloadDatalayer.product_upc_id && editModelOnloadDatalayer.product_upc_stockstatus) {
            editProductData.product_upc_id = editModelOnloadDatalayer.product_upc_id;
            editProductData.product_upc_stockstatus = [data.product.availability.messages[0]];
        }
        if (editModelOnloadDatalayer.product_merch_category) {
            editProductData.product_merch_category = editModelOnloadDatalayer.product_merch_category;
        }
        var tealiumData = $('.datalayer-view-event').data('tealium-view');
        if (!!tealiumData && tealiumData.length > 0) {
            editProductData.A1_ID = 'A1_ID' in tealiumData[0] ? tealiumData[0].A1_ID : '';
        }
        dataLayerAnalytics.datalayerEvent.push(editProductData);
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

/**
 * tealium event on click of edit product on cart page
 * @param {Object} data - response data
 * @param {couponCode} couponCode - applied coupon code
 */
function applyCoupon(data, couponCode) {
    if (data && data.couponCodeAmount) {
        var dataLayerAnalytics = {
            datalayerEvent: [{
                event_name: 'cart_applied_couponcode',
                cart_couponcode: couponCode,
                cart_couponcode_amount: data.couponCodeAmount.toString()
            }]
        };
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

/**
 * tealium event to capture server error on cart page
 * @param {Object} errorCode - errorCode
 * @param {couponCode} couponCode - applied coupon code
 */
function serverError(errorCode) {
    if (errorCode && errorCode.length > 0) {
        var dataLayerAnalytics = {
            datalayerEvent: [{
                event_name: 'error_serverside',
                error_serverside: errorCode
            }]
        };
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

/**
 * tealium event when giftbox is added to cart
 * @param {Object} data - response data
 */
function addGiftBox(data) {
    if (data && data.alreadyGiftboxAdded === true) {
        var dataLayerAnalytics = {
            datalayerEvent: [{ event_name: 'cart_giftbox_add' }]
        };
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

/**
 * build analytics data for click on checkout button
 * @param {Object} cartOnLoadData - datalayer on load data
 * @returns {Object} initiateCheckout - analytics data for click on checkout button
 */
function buildInitiateCheckoutFromOnLoadData(cartOnLoadData) {
    var initiateCheckout = {};
    initiateCheckout.cart_id = cartOnLoadData.cart_id;
    initiateCheckout.product_style_id = cartOnLoadData.product_style_id;
    initiateCheckout.product_name = cartOnLoadData.product_name;
    initiateCheckout.product_upc_id = cartOnLoadData.product_upc_id;
    initiateCheckout.product_upc_color_name = cartOnLoadData.product_upc_color_name;
    initiateCheckout.product_upc_color_code = cartOnLoadData.product_upc_color_code;
    initiateCheckout.product_quantity = cartOnLoadData.product_quantity ? cartOnLoadData.product_quantity.map(String) : [];
    initiateCheckout.product_upc_size = cartOnLoadData.product_upc_size;
    initiateCheckout.product_price_msrp = cartOnLoadData.product_price_msrp;
    initiateCheckout.product_price_shortmark = cartOnLoadData.product_price_shortmark;
    initiateCheckout.product_price_actual = cartOnLoadData.product_price_actual;
    return initiateCheckout;
}

/**
 * tealium event on initiate checkout by clicking on checkout button
 * @param {string} checkoutMethod - checkout method
 * @param {Object} initiateCheckoutData - response object of any update in basket
 */
// eslint-disable-next-line
function initiateRegularCheckout(checkoutMethod, initiateCheckoutData, subTotal, totalItems) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var totalItemsInt = totalItems;
    if (typeof totalItems === 'string') {
        totalItemsInt = parseInt(totalItems, 10);
    }
    var checkoutCta = {
        event_name: 'checkout_start',
        cart_checkout_method: checkoutMethod
    };
    var tealiumData = $('.datalayer-view-event').data('tealium-view');
    if (initiateCheckoutData.cartAnalyticsData && initiateCheckoutData.priceAndSize) {
        checkoutCta = Object.assign(checkoutCta, initiateCheckoutData.cartAnalyticsData, initiateCheckoutData.priceAndSize);
        if (checkoutCta.product_dept) {
            delete checkoutCta.product_dept;
        }
        if (checkoutCta.product_master_category) {
            delete checkoutCta.product_master_category;
        }
    } else if (tealiumData !== undefined) {
        var cartOnLoadData = tealiumData[0];
        var initiateCheckout = buildInitiateCheckoutFromOnLoadData(cartOnLoadData);
        checkoutCta = Object.assign(checkoutCta, initiateCheckout);
    }
    var subTotalAmt;
    if (tealiumData !== undefined && tealiumData[0].site_country && tealiumData[0].site_language) {
        checkoutCta.site_country = tealiumData[0].site_country;
        checkoutCta.site_language = tealiumData[0].site_language;
    }
    if (tealiumData !== undefined && tealiumData[0].site_country && tealiumData[0].site_country === 'ca') {
        subTotalAmt = subTotal.substring(1, subTotal.length - 4);
    } else {
        subTotalAmt = subTotal.substring(1, subTotal.length);
    }
    checkoutCta.cart_checkout_unitsrev = totalItemsInt.toString() + '|' + subTotalAmt;

    dataLayerAnalytics.datalayerEvent.push(checkoutCta);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * tealium event to capture save for later data
 * @param {string} pid - product Id
 */
function saveForLaterEvent(pid) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };

    var tealiumData = $('.datalayer-view-event').data('tealium-view')[0];
    var index = tealiumData.product_upc_id.indexOf(pid.toString());
    var saveForLaterCta = {
        event_name: 'cart_save_for_later',
        product_style_id: [tealiumData.product_style_id[index]],
        product_name: [tealiumData.product_name[index]],
        product_price_actual: [tealiumData.product_price_actual[index]],
        product_price_msrp: [tealiumData.product_price_msrp[index]],
        product_price_shortmark: [tealiumData.product_price_shortmark[index]],
        product_upc_color_code: [tealiumData.product_upc_color_code[index]],
        product_upc_color_name: [tealiumData.product_upc_color_name[index]],
        product_upc_id: [tealiumData.product_upc_id[index]],
        product_upc_size: [tealiumData.product_upc_size[index]]
    };
    if ($('.coupon-promotion-message').length > 0) {
        saveForLaterCta.event_name += ',error_serverside';
        saveForLaterCta.error_serverside = ['msg.coupon.not.applied'];
    }
    dataLayerAnalytics.datalayerEvent.push(saveForLaterCta);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * tealium event to capture mave to bag data from cart page anf wishlist page
 * @param {string} pid - product Id
 * @param {Object} data - response data
 * @param {string} event - event name
 */
function moveToBagEvent(pid, data, event) {
    if (data && data.items) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var productItem = data.cartAnalyticsData;
        var price = data.checkoutAnalyticsPriceAndSize;
        var index = productItem.product_upc_id.indexOf(pid.toString());
        var moveToBagData = {
            event_name: event,
            product_style_id: [productItem.product_style_id[index]],
            product_name: [productItem.product_name[index]],
            product_upc_id: [pid.toString()],
            cart_add_method: 'save for later',
            cart_units_update: '1',
            product_quantity: ['1'],
            product_upc_color_code: [productItem.product_upc_color_code[index]],
            product_upc_color_name: [productItem.product_upc_color_name[index]],
            product_upc_size: [price.product_upc_size[index]],
            product_price_msrp: [price.product_price_msrp[index]],
            product_price_shortmark: [price.product_price_shortmark[index]],
            product_price_actual: [price.product_price_actual[index]],
            cart_id: productItem.cart_id
        };
        var tealiumData = $('.datalayer-view-event').data('tealium-view');
        if (!!tealiumData && tealiumData.length > 0) {
            moveToBagData.A1_ID = 'A1_ID' in tealiumData[0] ? tealiumData[0].A1_ID : '';
        }
        dataLayerAnalytics.datalayerEvent.push(moveToBagData);
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

module.exports = {
    editProduct: editProduct,
    applyCoupon: applyCoupon,
    addGiftBox: addGiftBox,
    initiateRegularCheckout: initiateRegularCheckout,
    saveForLaterEvent: saveForLaterEvent,
    moveToBagEvent: moveToBagEvent,
    serverError: serverError
};

'use strict';

// track page breadcrums click action (grid top nav tracking)
// eslint-disable-next-line
function breadcrumbAnalytics(hrefLink, linkText) {
    if (linkText && hrefLink) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        dataLayerAnalytics.datalayerEvent.push({
            event_name: 'nav_click',
            nav_link_name: 'cn | ' + linkText,
            nav_link_destination: hrefLink
        });
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

module.exports = {
    breadcrumbAnalytics: breadcrumbAnalytics
};

'use strict';

/**
 * tealium event on click of place order button
 */
// eslint-disable-next-line
function placeOrderEvent(placeOrder) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var placeOrderCTA = {
        event_name: 'checkout_review_placeorder',
        page_name: 'checkout: review and place order'
    };
    if ($('.datalayer-view-event').data('tealium-view') !== undefined) {
        var orderOnLoadData = $('.datalayer-view-event').data('tealium-view')[0];
        if (orderOnLoadData !== undefined) {
            placeOrderCTA.product_style_id = orderOnLoadData.product_style_id;
            placeOrderCTA.product_name = orderOnLoadData.product_name;
            placeOrderCTA.product_upc_id = orderOnLoadData.product_upc_id;
            placeOrderCTA.product_upc_color_name = orderOnLoadData.product_upc_color_name;
            placeOrderCTA.product_upc_color_code = orderOnLoadData.product_upc_color_code;
            placeOrderCTA.product_quantity = orderOnLoadData.product_quantity;
            placeOrderCTA.product_upc_size = orderOnLoadData.product_upc_size;
            placeOrderCTA.product_price_msrp = orderOnLoadData.product_price_msrp;
            placeOrderCTA.product_price_shortmark = orderOnLoadData.product_price_shortmark;
            placeOrderCTA.product_price_actual = orderOnLoadData.product_price_actual;
        }
    }
    dataLayerAnalytics.datalayerEvent.push(placeOrderCTA);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * even on load of order review page
 * @param {Object} data - response data
 */
function reviewPageLoadEvent(data) {
    if (data && data.datalayerEvent) {
        var dataLayerAnalytics = {
            datalayerEvent: [data.datalayerEvent[0]]
        };
        $('body').trigger('datalayerEvent:view', dataLayerAnalytics);
    }
}

/**
 * tealium event on click of pay by link button for OOBO
 */
// eslint-disable-next-line

function payByLinkEvent() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var payByLinkCTA = {
        event_name: 'oboo pay by link',
        user_type: 'call center agent'
    };
    dataLayerAnalytics.datalayerEvent.push(payByLinkCTA);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

module.exports = {
    payByLinkEvent: payByLinkEvent,
    placeOrderEvent: placeOrderEvent,
    reviewPageLoadEvent: reviewPageLoadEvent
};

'use strict';
/* eslint-disable no-param-reassign */
var tealiumEnabled = JSON.parse($('.enable_tealium').text());
var categories = {};

/**
 * function to get product find method
 * @param {boolean} searchRedirect - SRP check
 * @returns {string} - pfm
 */
function getPFM(searchRedirect) {
    var findingMethod = cookieSet.get('finding_method');
    var pfm;
    if (searchRedirect) {
        if (findingMethod === 'popular-cat-preview') {
            pfm = ['internal keyword search: search preview window'];
        } else {
            pfm = ['internal keyword search: search results page'];
        }
    } else if (findingMethod === 'popular-cat-preview') {
        pfm = ['internal keyword search: search preview window'];
    } else if (findingMethod === '"external campaign referral"') {
        pfm = ['external campaign referral'];
    } else if (findingMethod === '"internal campaign referral"') {
        pfm = ['internal campaign referral'];
    } else if (findingMethod === '"external natural referral"') {
        pfm = ['external natural referral'];
    } else {
        pfm = ['browse'];
    }
    return pfm;
}
/**
 * open quick view analytics
 * @param {Object} data - response data
 * @param {number} productTileIndex - product tile index
 * @param {boolean} searchRedirect - to check search redirection
 */
function quickViewEvent(data, productTileIndex, searchRedirect, ctlContainerType) {
    if (data && productTileIndex) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var quickviewProductData = {};
        var pdpOnloadDatalayer = data.datalayerEvent[0];
        var productStyleId = [];
        var productName = [];
        productName.push(data.product.productName);
        productStyleId.push((data.product.masterProductData && data.product.masterProductData.ID) ? data.product.masterProductData.ID : data.product.id);
        quickviewProductData = {
            event_name: 'grid_click_product,product_quick_view',
            product_style_id: productStyleId,
            product_name: productName,
            product_price_msrp: pdpOnloadDatalayer.product_price_msrp,
            product_price_shortmark: pdpOnloadDatalayer.product_price_shortmark,
            product_price_actual: pdpOnloadDatalayer.product_price_actual
        };
        if (productTileIndex !== 'checkout') {
            quickviewProductData.grid_click_position = productTileIndex.toString();
        }
        if (pdpOnloadDatalayer.cart_items_forrecos) {
            quickviewProductData.cart_items_forrecos = pdpOnloadDatalayer.cart_items_forrecos;
            delete data.datalayerEvent[0].cart_items_forrecos;
        }
        if (pdpOnloadDatalayer.product_badge_name) {
            quickviewProductData.product_badge_name = pdpOnloadDatalayer.product_badge_name;
            delete data.datalayerEvent[0].product_badge_name;
        }
        if (pdpOnloadDatalayer.product_upc_color_name && pdpOnloadDatalayer.product_upc_color_code) {
            quickviewProductData.product_upc_color_code = pdpOnloadDatalayer.product_upc_color_code;
            quickviewProductData.product_upc_color_name = pdpOnloadDatalayer.product_upc_color_name;
            delete data.datalayerEvent[0].product_upc_color_code;
            delete data.datalayerEvent[0].product_upc_color_name;
        }
        if (pdpOnloadDatalayer.product_upc_size) {
            quickviewProductData.product_upc_size = pdpOnloadDatalayer.product_upc_size;
            delete data.datalayerEvent[0].product_upc_size;
        }
        if (pdpOnloadDatalayer.product_upc_id) {
            quickviewProductData.product_upc_id = pdpOnloadDatalayer.product_upc_id;
            delete data.datalayerEvent[0].product_upc_id;
        }
        if (pdpOnloadDatalayer.product_upc_stockstatus) {
            quickviewProductData.product_upc_stockstatus = [data.product.availability.messages[0]];
            delete data.datalayerEvent[0].product_upc_stockstatus;
        }
        var tealiumData = $('.datalayer-view-event').data('tealium-view');
        if (productTileIndex !== 'checkout') {
            quickviewProductData.product_dept = pdpOnloadDatalayer.product_dept;
            if (pdpOnloadDatalayer.product_master_category) {
                quickviewProductData.product_master_category = pdpOnloadDatalayer.product_master_category;
            } else if (data.product.masterProductData.category) {
                quickviewProductData.product_master_category = data.product.masterProductData.category;
            }
            if (!!tealiumData && tealiumData.length > 0) {
                quickviewProductData.product_merch_category = [tealiumData[0].page_name];
            }
        } else {
            quickviewProductData.product_merch_category = pdpOnloadDatalayer.product_merch_category;
        }
        // eslint-disable-next-line no-undef
        if (searchRedirect) {
            quickviewProductData.event_name = 'grid_click_product,product_quick_view';
        }
        var pfm = getPFM(searchRedirect);
        if (productTileIndex !== 'checkout' && !!pfm) {
            quickviewProductData.product_finding_method = pfm;
        }
        categories = {
            product_master_category: quickviewProductData.product_master_category,
            product_merch_category: quickviewProductData.product_merch_category,
            product_dept: quickviewProductData.product_dept
        };
        if (!!tealiumData && tealiumData.length > 0) {
            quickviewProductData.A1_ID = 'A1_ID' in tealiumData[0] ? tealiumData[0].A1_ID : '';
        }
        if (productTileIndex === 'ctlQuickView') {
            quickviewProductData.event_name = 'product_ctl_view,product_quick_view';
            if (!!tealiumData && tealiumData.length > 0) {
                quickviewProductData.product_merch_category = 'product_merch_category' in tealiumData[0] ? tealiumData[0].product_merch_category : '';
            }
            delete quickviewProductData.grid_click_position;
            if (ctlContainerType.toLowerCase() === 'einstein') {
                quickviewProductData.product_finding_method = ['ctl_einstein'];
            } else {
                quickviewProductData.product_finding_method = ['ctl_custom'];
            }
        }
        if (productTileIndex === 'pdpBundle') {
            quickviewProductData.event_name = 'product_pdp_bundle_set_tile_click,product_quick_view';
            quickviewProductData.product_finding_method = ['product:pdp_bundleset'];
            if (!!tealiumData && tealiumData.length > 0) {
                tealiumData = tealiumData[0];
                let index = tealiumData.product_style_id.indexOf(productStyleId[0]);
                quickviewProductData.product_merch_category = 'product_merch_category' in tealiumData ? [tealiumData.product_merch_category[index]] : '';
            }
            delete quickviewProductData.grid_click_position;
        }
        dataLayerAnalytics.datalayerEvent.push(quickviewProductData);
        ['product_style_id', 'product_name', 'product_price_msrp', 'product_price_actual', 'product_price_shortmark'].forEach(e => delete data.datalayerEvent[0][e]);

        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

/**
 * to get selected product variation data
 * @param {Object} data - data object used to fill in dynamic portions of the html
 * @param {string} eventName - event name of interactions
 * @returns {Object} variationData - variation data of selected variant
 */
// eslint-disable-next-line
function getVariationSelectedData(data, eventName, pageType) {
    var variationData = {};
    if (tealiumEnabled && data) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        variationData.event_name = eventName;
        var priceData = data.analyticaPriceData;
        var badgeData = data.allBadges;
        if (data.variationAttributes) {
            var colorcodeVariationAttr = data.variationAttributes.find(variationAttribute => variationAttribute.attributeId === 'colorCode');
            if (colorcodeVariationAttr) {
                var productUpcColorName = [];
                productUpcColorName.push(colorcodeVariationAttr.displayValue);
                var productUpcColorCode = [];
                productUpcColorCode.push(colorcodeVariationAttr.colorcode);
                variationData.product_upc_color_name = productUpcColorName;
                variationData.product_upc_color_code = productUpcColorCode;
            }
        }
        var productStyleId = [];
        productStyleId.push(data.masterProductData && 'ID' in data.masterProductData ? data.masterProductData.ID : data.id);
        variationData.product_style_id = productStyleId;
        var productName = [];
        productName.push(data.productName);
        variationData.product_name = productName;
        if (badgeData !== null && badgeData !== '') {
            var productBadgeName = [];
            productBadgeName.push(badgeData);
            variationData.product_badge_name = productBadgeName;
        }
        if (pageType !== 'cart') {
            variationData.product_dept = categories.product_dept;
            variationData.product_merch_category = categories.product_merch_category;
            variationData.product_master_category = categories.product_master_category;
        }

        var productPriceActual = [];
        productPriceActual.push(priceData.outOfDoorPrice);
        variationData.product_price_actual = productPriceActual;

        var productPriceShortmark = [];
        productPriceShortmark.push(priceData.markdownPrice);
        variationData.product_price_shortmark = productPriceShortmark;

        var productPriceMsrp = [];
        productPriceMsrp.push(priceData.fullPrice);
        variationData.product_price_msrp = productPriceMsrp;
        if (data.size !== null) {
            var productUpcId = [];
            productUpcId.push(data.id);
            variationData.product_upc_id = productUpcId;

            var productUpcStockstatus = [];
            productUpcStockstatus.push(data.availability.preOrder ? 'Pre-Order' : data.availability.messages[0]);
            variationData.product_upc_stockstatus = productUpcStockstatus;

            var productUpcSize = [];
            productUpcSize.push(data.size);
            variationData.product_upc_size = productUpcSize;
        }
        var tealiumData = $('.datalayer-view-event').data('tealium-view');
        if (!!tealiumData && tealiumData.length > 0) {
            variationData.A1_ID = 'A1_ID' in tealiumData[0] ? tealiumData[0].A1_ID : '';
        }
        if (pageType === 'pdp') {
            var $categoryData = $('.quickview__modal__body .product-detail');
            variationData.product_dept = $categoryData.data('dept');
            variationData.product_master_category = $categoryData.data('master-category');
            variationData.product_merch_category = !!tealiumData && tealiumData.length > 0 && 'product_merch_category' in tealiumData[0] ? tealiumData[0].product_merch_category : '';
            if (!!tealiumData && tealiumData.length > 0 && tealiumData[0].page_type && tealiumData[0].page_type.includes('bundle')) {
                tealiumData = tealiumData[0];
                let index = tealiumData.product_style_id.indexOf(productStyleId[0]);
                variationData.product_merch_category = 'product_merch_category' in tealiumData ? [tealiumData.product_merch_category[index]] : '';
            }
        }
        if (eventName === 'product_change_color') {
            variationData.product_colorswatch_location = 'quickview';
        }
        if (eventName !== 'cart_add,cart_open') {
            dataLayerAnalytics.datalayerEvent.push(variationData);
            $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
        }
    }
    return variationData;
}
module.exports = {
    getVariationSelectedData: getVariationSelectedData,
    // eslint-disable-next-line camelcase
    getAddToCartData: function (data, cart_id, event, QVType) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var eventName = 'cart_add,cart_open';
        var productAddToCartData = getVariationSelectedData(data, eventName);
        if (event) {
            productAddToCartData.event_name = event;
        }
        delete productAddToCartData.product_badge_name;
        // eslint-disable-next-line camelcase
        productAddToCartData.cart_id = cart_id;
        var tealiumData = $('.datalayer-view-event').data('tealium-view');
        if (!QVType || QVType === null || QVType === '') {
            productAddToCartData.cart_add_method = 'quickview';
        } else if (QVType === 'bundle') {
            productAddToCartData.cart_add_method = 'pdp_bundleset';
            productAddToCartData.product_bundle_style_id = !!tealiumData && tealiumData.length > 0 && tealiumData[0].product_bundle_style_id && tealiumData[0].product_bundle_style_id.length ? [tealiumData[0].product_bundle_style_id[0]] : [];
        } else if (QVType === 'Einstein') {
            productAddToCartData.cart_add_method = 'ctl_einstein';
        } else {
            productAddToCartData.cart_add_method = 'ctl_custom';
        }
        var qty = data.quantity || data.selectedQuantity;
        productAddToCartData.product_quantity = qty ? [qty.toString()] : [];
        productAddToCartData.cart_units_update = qty ? qty.toString() : '';
        if (!event) {
            productAddToCartData.product_dept = categories.product_dept;
            productAddToCartData.product_master_category = categories.product_master_category;
        }
        productAddToCartData.product_merch_category = categories.product_merch_category;
        if (!!tealiumData && tealiumData.length > 0) {
            productAddToCartData.A1_ID = 'A1_ID' in tealiumData[0] ? tealiumData[0].A1_ID : '';
        }
        var $categoryData = $('.quickview__modal__body .product-detail');
        if (productAddToCartData.cart_add_method.includes('ctl_')) {
            productAddToCartData.product_dept = $categoryData.data('dept');
            productAddToCartData.product_master_category = $categoryData.data('master-category');
            productAddToCartData.product_merch_category = !!tealiumData && tealiumData.length > 0 && 'product_merch_category' in tealiumData[0] ? tealiumData[0].product_merch_category : '';
        }
        if (!!tealiumData && tealiumData.length > 0 && QVType === 'bundle') {
            tealiumData = tealiumData[0];
            let pid = productAddToCartData.product_style_id[0];
            let index = tealiumData.product_style_id.indexOf(pid);
            productAddToCartData.product_dept = $categoryData.data('dept');
            productAddToCartData.product_master_category = $categoryData.data('master-category');
            productAddToCartData.product_merch_category = 'product_merch_category' in tealiumData ? [tealiumData.product_merch_category[index]] : '';
        }
        dataLayerAnalytics.datalayerEvent.push(productAddToCartData);
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    },
    quickViewEvent: quickViewEvent,
    getPFM: getPFM
};

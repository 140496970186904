'use strict';

/**
 * creatAccountAndLoginEvent event
 *@param {string} event - event name
 *@param {boolean} emailSignup - is email sign up enabled
 *@param {boolean} fromSignUpModal - login from sign up modal
 */
function creatAccountAndLoginEvent(event, emailSignup, fromSignUpModal, email) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    // eslint-disable-next-line
    var event_name = [];
    var createAccountEvent = {};
    // eslint-disable-next-line
    if (event === 'register') {
        event_name.push('account_creation_success');
        event_name.push('account_signin_success');
        createAccountEvent.account_creation_location = 'create an account';
        createAccountEvent.account_signin_location = 'create an account';
        createAccountEvent.customer_email_address = email;
        if (emailSignup) {
            event_name.push('email_signup_success');
            createAccountEvent.email_signup_location = 'create an account';
        }
        // eslint-disable-next-line
    } else if (event === 'orderConfirmation') {
        event_name.push('account_creation_success');
        event_name.push('account_signin_success');
        createAccountEvent.account_creation_location = 'order confirmation';
        createAccountEvent.account_signin_location = 'order confirmation';
        if (emailSignup) {
            event_name.push('email_signup_success');
            createAccountEvent.email_signup_location = 'order confirmation';
        }
        // eslint-disable-next-line
    } else if (event === 'login' && fromSignUpModal) {
        event_name.push('account_signin_attempt');
        event_name.push('account_signin_success');
        createAccountEvent.customer_email_address = email;
        createAccountEvent.account_signin_location = 'sign in modal';
        // eslint-disable-next-line
    } else if (event === 'login_failure' && fromSignUpModal) {
        event_name.push('account_signin_attempt');
        event_name.push('account_signin_failed');
        createAccountEvent.customer_email_address = email;
        createAccountEvent.account_signin_location = 'sign in modal';
        // eslint-disable-next-line
    } else if (event === 'login' && !fromSignUpModal) {
        event_name.push('account_signin_success');
        createAccountEvent.customer_email_address = email;
        createAccountEvent.account_signin_location = 'sign in page';
        // eslint-disable-next-line
    } else if (event === 'signinOpen') {
        event_name.push('account_signin_modaldisplay');
        createAccountEvent.account_signin_location = 'sign in modal';
    }

    var eventName = '';
    // eslint-disable-next-line
    for (var event = 0; event < event_name.length; event ++) {
        if (event !== event_name.length - 1) {
            eventName += event_name[event] + ',';
        } else {
            eventName += event_name[event];
        }
    }
    createAccountEvent.event_name = eventName;
    if (createAccountEvent.event_name.indexOf('account_signin_success') !== -1) {
        var tealiumData = $('.datalayer-view-event').data('tealium-view');
        if (!!tealiumData && tealiumData.length > 0) {
            createAccountEvent.A1_ID = 'A1_ID' in tealiumData[0] ? tealiumData[0].A1_ID : '';
        }
    }
    dataLayerAnalytics.datalayerEvent.push(createAccountEvent);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * emailOptInTracking
 * @param {string} emailSignUpLocation - email_signup_location
 * @param {string} email - email
 */
function emailOptInTracking(emailSignUpLocation, email) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push({
        event_name: 'email_signup_success',
        email_signup_location: emailSignUpLocation,
        customer_email_address: email
    });
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * forgotPasswordCTA event
 */
function forgetPasswordCTA() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push({ event_name: 'account_forgotpw_modaldisplay' });
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

 /**
 * checkEmailCTA event
 */
function checkEmailCTA() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push({ event_name: 'account_forgotpw_checkemail_modaldisplay' });
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

module.exports = {
    creatAccountAndLoginEvent: creatAccountAndLoginEvent,
    emailOptInTracking: emailOptInTracking,
    forgetPasswordCTA: forgetPasswordCTA,
    checkEmailCTA: checkEmailCTA
};

'use strict';

/**
 * Trigger remove item from mini cart tealium event
 * @param {Object} deletedPliAnalyticsData - deleted product analytic data
 */
function miniCartRemoveAnalytics(deletedPliAnalyticsData, errorCodes) {
    if (deletedPliAnalyticsData) {
        if (errorCodes) {
            deletedPliAnalyticsData.event_name+= ',error_serverside';
            deletedPliAnalyticsData.error_serverside = errorCodes;
        }
        var dataLayerAnalytics = {
            datalayerEvent: [deletedPliAnalyticsData]
        };
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

/**
 * Trigger add or subtarct product quantity from mini cart tealium event
 * @param {Object} resData - add/subtarct product deom minicart response data
 * @param {Object} reqData - add/subtarct product deom minicart request data
 */
function miniCartAddOrSubtractEvent(resData, reqData) {
    if (resData && reqData) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var productItem = resData.items.find(item => item.id == reqData.pid);   //eslint-disable-line
        var price = productItem.analyticaPriceData;
        var updatedProductData = {
            event_name: 'cart_update',
            product_style_id: [productItem.masterProductData.ID],
            product_name: [productItem.masterProductData.name],
            product_upc_id: [reqData && reqData.pid ? reqData.pid.toString() : ''],
            cart_units_update: reqData.quantityUpdate,
            product_price_msrp: [price.fullPrice.toString()],
            product_price_shortmark: [price.outOfDoorPrice.toString()],
            product_price_actual: [price.markdownPrice.toString()],
            cart_id: resData.basketUUID
        };
        if (productItem.categoryLevels.product_dept) {
            updatedProductData.product_dept = [productItem.categoryLevels.product_dept];
        }
        if (productItem.variationAttributes) {
            var colorcodeVariationAttr = productItem.variationAttributes.find(variationAttribute => variationAttribute.attributeId === 'colorCode');
            if (colorcodeVariationAttr) {
                updatedProductData.product_upc_color_name = [colorcodeVariationAttr.displayValue];
                updatedProductData.product_upc_color_code = [colorcodeVariationAttr.colorcode];
            }
        }
        if (productItem.size) {
            updatedProductData.product_upc_size = [productItem.size];
        }
        var tealiumData = $('.datalayer-view-event').data('tealium-view');
        if (!!tealiumData && tealiumData.length > 0) {
            updatedProductData.A1_ID = 'A1_ID' in tealiumData[0] ? tealiumData[0].A1_ID : '';
        }
        dataLayerAnalytics.datalayerEvent.push(updatedProductData);
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

module.exports = {
    miniCartAddOrSubtractEvent: miniCartAddOrSubtractEvent,
    miniCartRemoveAnalytics: miniCartRemoveAnalytics
};

'use strict';

/**
 * checkoutAddressPaymentSuggestEvent event
 */
function checkoutAddressPaymentSuggestEvent() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push({
        event_name: 'site_interaction',
        site_interaction: 'loqate:select loqate address:checkout payment'
    });
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * on click of submit payment button
 */
function onSubmitPayment() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push({
        event_name: 'checkout_payment_gotoreview',
        page_name: 'checkout: payment'
    });
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
/**
 * checkoutAddnewAddressEvent event
 * @param {Object} data - data
 */
function onBillingPageLoad(data) {
    var itemSize = [];
    var productPriceMsrp = [];
    var productPriceShortmark = [];
    var productPriceActual = [];
    var globalData = data.datalayerEvent[0];
    var eventName = 'checkout_payment_page';
    var onLoadData = {};
    if (data.order && data.order.totals && data.order.totals.errorCodes && data.order.totals.errorCodes.length > 0) {
        onLoadData = {
            event_name: eventName+',error_serverside',
            error_serverside : data.order.totals.errorCodes
        };
    } else {
        onLoadData = {
            event_name: eventName
        };
    }
    var tealiumData = $('.datalayer-view-event').data('tealium-view');

    if (globalData) {
        onLoadData.site_brand = globalData.site_brand;
        onLoadData.site_country = globalData.site_country;
        onLoadData.site_language = globalData.site_language;
        onLoadData.site_currency_code = globalData.site_currency_code;
        onLoadData.site_version = globalData.site_version;
        onLoadData.page_name = globalData.page_name;
        onLoadData.page_type = globalData.page_type;
        onLoadData.user_status = globalData.user_status;
        onLoadData.cart_items_forrecos = globalData.cart_items_forrecos;
        onLoadData.customer_email_address = globalData.customer_email_address;
        onLoadData.customer_loyalty_id = globalData.customer_loyalty_id;
        onLoadData.user_hashed_emailaddress = globalData.user_hashed_emailaddress;
        onLoadData.hashed_sfcc_customer_num = globalData.hashed_sfcc_customer_num;
        onLoadData.hashed_loyalty_user_id = globalData.hashed_loyalty_user_id;
        onLoadData.user_gender = globalData.user_gender;
        onLoadData.user_loyalty_points = globalData.user_loyalty_points;
        onLoadData.user_loyalty_status = globalData.user_loyalty_status;
        onLoadData.user_type = globalData.user_type;
        onLoadData.user_last_purchased_date = globalData.user_last_purchased_date;
        onLoadData.page_level_one = globalData.page_levelone;
    }
    if (tealiumData[0].customer_email_address) {
        onLoadData.customer_email_address = tealiumData[0].customer_email_address;
    }
    if (data.order && data.order.checkoutShippingAnalyticsData) {
        onLoadData.cart_id = data.order.checkoutShippingAnalyticsData.cart_id;
        onLoadData.product_style_id = data.order.checkoutShippingAnalyticsData.product_style_id;
        onLoadData.product_name = data.order.checkoutShippingAnalyticsData.product_name;
        onLoadData.product_quantity = data.order.checkoutShippingAnalyticsData.product_quantity.map(String);
        onLoadData.product_upc_color_name = data.order.checkoutShippingAnalyticsData.product_upc_color_name;
        onLoadData.product_upc_color_code = data.order.checkoutShippingAnalyticsData.product_upc_color_code;
        onLoadData.product_upc_id = data.order.checkoutShippingAnalyticsData.product_upc_id;
    }
    if (data.order && data.order.items) {
        data.order.items.analyticaitems.forEach(function (item) {
            if (item.size) {
                itemSize.push(item.size);
            }
            if (item.analyticaPriceData) {
                productPriceMsrp.push(item.analyticaPriceData.fullPrice.toString());
                productPriceShortmark.push(item.analyticaPriceData.markdownPrice.toString());
                if (item.proratedPrice) {
                    productPriceActual.push(item.proratedPrice.toString());
                } else {
                    productPriceActual.push(item.analyticaPriceData.outOfDoorPrice.toString());
                }
            }
        });
    }
    var paymentMethod = data.order && data.order.billing && data.order.billing.payment && data.order.billing.payment.selectedPaymentInstruments && data.order.billing.payment.selectedPaymentInstruments.length > 0 && data.order.billing.payment.selectedPaymentInstruments[0].selectedAdyenPM ? data.order.billing.payment.selectedPaymentInstruments[0].selectedAdyenPM : null;
    if (paymentMethod === 'paypal_ecs') {
        onLoadData.cart_checkout_method = paymentMethod;
    } else if (paymentMethod === 'Apple pay') {
        onLoadData.cart_checkout_method = 'applepay';
    } else if (globalData && globalData.cart_checkout_method) {
        onLoadData.cart_checkout_method = 'guest checkout';
    } else if (data.customer && data.customer.registeredUser) {
        onLoadData.cart_checkout_method = 'logged in checkout';
    } else {
        onLoadData.cart_checkout_method = 'guest checkout';
    }
    onLoadData.order_subtotal = (data.order && data.order.totals && data.order.totals.subTotal) ? data.order.totals.subTotal.substring(1) : '';
    onLoadData.product_price_msrp = productPriceMsrp;
    onLoadData.product_price_actual = productPriceActual;
    onLoadData.product_price_shortmark = productPriceShortmark;
    onLoadData.product_upc_size = itemSize;

    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push(onLoadData);
    $('body').trigger('datalayerEvent:view', dataLayerAnalytics);
}
/**
 * on click of apply gift card successfully
 */
function giftCardSuccess() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push({
        event_name: 'checkout_apply_giftcardsuccess'
    });
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
module.exports = {
    checkoutAddressPaymentSuggestEvent: checkoutAddressPaymentSuggestEvent,
    onSubmitPayment: onSubmitPayment,
    onBillingPageLoad: onBillingPageLoad,
    giftCardSuccess: giftCardSuccess
};
